import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Line = ({ to, text }) => (
  <StaticQuery
    query=
      {graphql`
        query {
          line: file(relativePath: { eq: "line.png" }) {
            childImageSharp {
              fixed(width: 250) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
    render={data => (
      <a target="_blank" href={to} className="line-qr-link" rel="noopener noreferrer">
        {data.line.childImageSharp.fixed ?
          <Img alt={text} fixed={data.line.childImageSharp.fixed}/>
          : { text }
        }
      </a>
    )}
  />
);

Line.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Line;