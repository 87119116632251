import React, {useContext} from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../utils/style';
import {LogoutButton} from '../Auth/Auth';
import StoreContext from "../shared/Context/StoreContext";
import IcomoonIcon from '../shared/IcomoonIcon';

const HeaderContacts = styled('div')`
  flex-basis: 57%;
  text-align: right;
  padding-top: 13px;
  .header-search-wrapper {
    display: inline-block;
  }
  .header-contact-group {
    display: inline-block;
    vertical-align: top;
    .button {
      padding: 9px 23px 5px;
      margin: 0 25px;
      font-size: 1.1rem;
      text-align: left;
      display: inline-block;
      vertical-align: middle;
    }
    .green-button {
      float: right;
      margin: 0;
      span {
        font-size: 16px;
        font-weight: bold;
        display: inline-block;
        vertical-align: baseline;
      }
      svg {
        margin-right: 10px;
      }
    }
    .register-button {
      height: 47px;
      width: 178px;
      text-align: center;
      span {
        display: inline-block;
        vertical-align: middle;
        margin-top: 2px;
        line-height: 22px;
      }
      &:hover, &:active {
        padding: 10px 23px 6px;
      }
    }
    .logout-button {
      height: auto;
      padding: 14px 23px;
      width: 178px;
      text-align: center;
      svg {
        margin-left: 7px;
        vertical-align: top !important;
      }
    }
    
  }
  .header-image-wrap, .button {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
  }
  .header-image-wrap .gatsby-image-wrapper {
    border-radius: 100%;
  }
  .popup-content {
    text-align: center;
  }
  
  @media (max-width: ${breakpoints.lg}) {
    flex-basis: 63%;
    .header-contact-group {
      .button {
        margin: 0 5px;
        padding: 9px 7px 5px; 
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    display: none;
    .header-contact-group {
      display: none;
    }
    .header-search-wrapper {
      display: block;
      width: 100%;
      position: relative;
      .close-menu {
        position: absolute;
        top: 22px;
        right: 5px;
        background: none;
      }
    }
  }
`;

const showHideChat = (id, frameID) => {
  const chatBox = document.getElementById(id);
  let chatIframe = document.getElementById(frameID);

  chatBox.classList.add('open');

  if (chatBox.classList.contains('open') && (!chatIframe.src || chatIframe.src === 'about:blank')) {
    chatIframe.src = process.env.FRESHCHAT_LOCAL;
  }
};

export default ({ children }) => {
  const value = useContext(StoreContext);
  const username = value.username;
  return(
  <HeaderContacts id="header-contacts-menu" className="header-contacts-menu">
    {children}
    <div className="header-contact-group">
      <button className="button green-button live-chat-green-button" onClick={() => showHideChat('chat-wrapper', 'chat-frame')}>
        <IcomoonIcon icon={'chat-2'} color={color.black} size={30}/>
        <span>Hỗ trợ trực tuyến</span>
      </button>
      {username ?
        <LogoutButton/> :
        <Link className="button transparent-button register-button" to="/register/" text="สมัครสมาชิก">
          <span>Đăng Ký</span>
        </Link>
      }
    </div>
  </HeaderContacts>
)};
