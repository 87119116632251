import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Coin = () => (
  <StaticQuery
    query=
      {graphql`
        query {
          coin: file(relativePath: { eq: "coin-baht.png" }) {
            childImageSharp {
              fixed(width: 30) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
    render={data => (
      data.coin.childImageSharp.fixed &&
        <Img alt="Balance" fixed={data.coin.childImageSharp.fixed}/>

    )}
  />
);

export default Coin;