import React from 'react';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const El = styled('button')`
   display:none;
   width:52px;
   height:48px;
   background: ${color.transparentWhite1};
   border: none;
   border-radius: 24px;
   padding: 4px;
   &:active, &:focus {
     outline:none;
     opacity: 0.7;
   }
   
   @media (max-width: ${breakpoints.md}) {
     display:inline-block;
     position:absolute;
     right: 25px;
     top:13px;
   }
   @media (max-width: ${breakpoints.sm}) {
     right: 20px;
   }
   @media (max-width: ${breakpoints.xs}) {
     right: 15px;
     top:15px;
     width: 44px;
     height: 44px;
     padding: 5px 11px;
     svg {
       max-width: 100%;
     }
   }
`;

const TopSearch = () => {
  const handleClick = (e) => {
    e.preventDefault();
    const block = document.getElementById('header-contacts-menu');
    const layout = document.getElementById('all-content');
    const navWrap = document.getElementById('nav-wrap');
    layout.classList.toggle('inter-blocked');
    block.classList.toggle('open');
    navWrap.classList.toggle('search-open');
  };

  return (
    <El aria-label="Tìm kiếm" onClick={(e) => handleClick(e)}>
      <IcomoonIcon className="contact-menu-icon" icon="search-2" color={color.white} size={26} />
    </El>
  );
};

export default TopSearch;