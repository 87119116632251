import React from 'react';
const Gift = ({ width = '20', className = '', height = '20', viewBox='0 0 20 20' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="M13.7 4.6C12.6 5.7 10.9 6 10.1 6m0 0s-.5-3.1.7-4.4m2.9 3c.8-.8.8-2.2 0-3-.8-.8-2.1-.8-2.9 0m-4.5 3C7.4 5.7 9.1 6 9.9 6m0 0s.5-3.1-.7-4.4m-2.9 3c-.8-.8-.8-2.2 0-3 .8-.8 2.1-.8 2.9 0" stroke="#FF5656"/>
      <path stroke="#F7BC00" d="M2 10c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h16c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1h-1v8c0 .6-.4 1-1 1H4c-.6 0-1-.4-1-1v-8zm14.84 0H2.965M10 19V6"/>
    </g>
  </svg>;

  export default Gift;