import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../utils/style';
import IcomoonIcon from '../shared/IcomoonIcon';



const El = styled('button')`
   display: none;
   -webkit-appearance: none;
   background: ${color.red};
   border: 0;
   span {
     display: none;
   }
   padding-right: 20px;
   &:active, &:focus {
     outline:none;
     opacity: 0.7;
   }
   
   @media (max-width: ${breakpoints.md}) {
    display:inline-block;
    color: ${color.white};
    font-size: 1.2rem;
    line-height: 1;
   }

`;

const MobileCloseLink = ( props ) => {
  return (
    <El className="close-menu" aria-label="Close" onClick={props.handlerFunction}>
      <IcomoonIcon icon="gclub-close" color={color.white} size={30} />
      <span>Close</span>
    </El>
  );
};

export default MobileCloseLink;

MobileCloseLink.propTypes = {
  handlerFunction: PropTypes.func.isRequired
};