import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Star5 = ({ text }) => (
  <StaticQuery
    query=
      {graphql`
        query {
          star: file(relativePath: { eq: "star-5.png" }) {
            childImageSharp {
              fixed(width: 32) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
    render={data => (
      <div className="star-holder">
      {data.star.childImageSharp.fixed ?
        <Img alt={text} fixed={data.star.childImageSharp.fixed}/>
        : <span>{ text }</span>
      }
      </div>
    )}
  />
);

Star5.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Star5;