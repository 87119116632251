import React from 'react';
import styled from '@emotion/styled';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const El = styled('button')`
   svg:nth-of-type(2) {
     display: none !important;
   }
   &.clicked {
     svg:nth-of-type(1) {
       display: none !important;
     }
     svg:nth-of-type(2) {
       display: inline-block !important;
       path {fill: ${color.white} !important;}
       filter: drop-shadow(1px 1px 6px ${color.peach});
     }
   }
`;

const Hamburger = () => {
  const handleClick = (e) => {
    e.preventDefault();
    const ul = document.getElementById('nav');
    const layout = document.getElementById('all-content');
    layout.classList.toggle('bg-added');
    ul.classList.toggle('open');
    const target = document.getElementById('mobile-menu-open');
    target.classList.toggle('clicked');
  };

  return (
    <El id="mobile-menu-open" aria-label="Menu" onClick={(e) => handleClick(e)}>
      <IcomoonIcon className="mobile-menu-icon" icon="menu" color={color.red} size={30} />
      <IcomoonIcon className="mobile-menu-close-icon" icon="close-menu" color={color.red} size={27} />
    </El>
  );
};

export default Hamburger;