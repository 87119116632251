import React from 'react';
import PropTypes from 'prop-types';
import PopupWindow, { invokePopup} from '../../shared/PopupWindow';
import LoginFormNoPopup from './LoginFormNoPopup';


const hideAllErrors = () => {
  const errorBoxes = document.getElementsByClassName('errorMsg');
  if (errorBoxes.length) {
    Object.values(errorBoxes).forEach(function(element) {
      element.classList.remove('visible');
    });
  }
};

const LoginFormOpen = (event, popupId) => {
  invokePopup(event, popupId);
  hideAllErrors();
};

const LoginForm = (popupId) => {
  return (
    <PopupWindow
      title="Đăng Nhập"
      popupId={popupId.popupId}
      extraClass="title-orange"
    >
      <LoginFormNoPopup popupId={popupId.popupId} />
    </PopupWindow>
  );
};
export default LoginForm;
export { LoginFormOpen };

LoginForm.propTypes = {
  popupId: PropTypes.string,
};
