import React from 'react';
import MainMenu from '../Menus/MainMenuNew';
import styled from '@emotion/styled';
import { breakpoints } from '../../utils/style';

const AppHeader = styled('header')`
  &.on-front {
    position: relative;
    z-index: 10;
  }
  
  
  @media (min-width: ${breakpoints.mdMin}) {
    position: relative;
    z-index: 10;
    max-height: 97px;
    margin-bottom: 2px;
  }
  
  @media (max-width: ${breakpoints.md}) {
    display: block;
    width: 100%;
    padding: 0;
    &.on-front {
      position: static;
    }
  }
`;

export default ({ location }) => (
  <AppHeader id="app-header" className="app-header">
    <MainMenu isHome={location === '/'}/>
  </AppHeader>
);