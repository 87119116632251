import React from "react"

const Refresh = ({ width="18", height="18", viewBox="0 0 18 18", color="#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <g fill="none" fillRule="evenodd" opacity=".7">
        <path
          d="M5.588 5.225a.75.75 0 01.15 1.05A4.52 4.52 0 004.813 9a4.563 4.563 0 004.046 4.533l-.014-.016a.75.75 0 01-.073-.976l.073-.084a.75.75 0 01.976-.073l.084.073 1.329 1.328c.266.267.29.683.073.977l-.073.084-1.329 1.329a.75.75 0 01-1.133-.977l.073-.084.07-.069A6.061 6.061 0 013.313 9c0-1.32.436-2.573 1.225-3.625a.751.751 0 011.05-.15zm4.317-3.4c.267.267.29.683.073.977l-.073.084-.069.07A6.061 6.061 0 0115.437 9c0 1.32-.436 2.573-1.225 3.625a.751.751 0 01-1.2-.9A4.52 4.52 0 0013.937 9a4.562 4.562 0 00-4.045-4.533l.013.016a.75.75 0 01.073.976l-.073.084a.75.75 0 01-.976.073l-.084-.073-1.329-1.328a.752.752 0 01-.073-.977l.073-.084 1.329-1.329a.75.75 0 011.06 0z"
          fill={color}
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M0 0h18v18H0z" />
      </g>
    </svg>
  )
}

export default Refresh