import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, color, wrapper1224 } from '../../utils/style';
import { Link } from 'gatsby';
import PopupWindow from '../shared/PopupWindow';
import LineQR from '../Header/Line';
import Freshchat from './Freshchat2023';

const FooterBlock = styled('footer')`
  background: rgba(0,0,0,0.25);
  .top-footer-col {
    display: inline-block;
    vertical-align: top;
  }
  .top-footer-left {
    width: 43%;
  }
  .top-footer-right {
    width: 56%;
    text-align: center;
    padding-top: 80px;
    ul { opacity: 0.7; }
  }
  .menu-col {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    padding: 0 10px 20px;
    text-align: left;
  }
  .menu-col-wide {
    width: 40%;
    padding: 0 10px 0 20px;
    ul {
      column-count: 2;
      column-gap: 2px;
    }
  }
  ul {
    margin:20px 0;
    list-style: none;
    li {
      font-size: 14px;
      margin: 0 15px 10px 0;
    }
    a {
        color: ${color.white}
    }
    a:hover, a:active, .active {
       color: ${color.yellow};
       text-decoration: none;
    }
  }
  .h4 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .logo {
    width: 200px;
    max-width: 90%;
    display: inline-block;
    margin: 5px 0 25px;
  }
  .footer-top {
    padding: 20px 0;
  }
  .social-buttons {
    padding-right: 38px;
    text-align: right;
    a {
      display: inline-block;
      margin-left: 5px;
      &:hover, &:active {
        svg {
          filter: drop-shadow(1px 1px 4px ${color.peach});
        }
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    padding: 0 20px;
    
    .footer-bottom {
      max-width: 87%;
      margin: 0;
    }
    .social-buttons {
      padding: 0;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 50px;
    .footer-top {
      position: relative;
      padding-bottom: 60px;
      .footer-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 100%;
        width: 100%;
      }
    }
    .top-footer-left {
      width: 100%;
    }
    .top-footer-right {
      padding-top: 15px;
    }
    .logo {
      display: none;
    } 
    .top-footer-left, .top-footer-right {
      width: 100%;
      text-align: center;
    }
    .menu-col:nth-of-type(odd) {
      padding-left: 2px;
    }
    .menu-col:nth-of-type(4) {
      padding-right: 0;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .footer-top {
      padding-bottom: 110px;
      .footer-bottom {
        padding: 10px;
      }
    }
    .social-buttons {
      padding: 0;
      text-align: center;
    }
    .menu-col {
      width: 50%;
    }
    .menu-col-wide {
      padding: 0 0 0 10px;
      ul {
        column-count: 1;
        column-gap: 0;
      }
    }
  }
`;

const Wrapper = styled('div')`
  ${wrapper1224}
`;

const Copy = styled('div')`
   font-size: 14px;
   line-height: 1.2;
   text-align: center;
   span {
     opacity: 0.7;
   } 
   a { 
     margin: 0 0 0 20px;
     color: ${color.white};
     text-decoration: underline;
   }
   @media (max-width: ${breakpoints.sm}) {
      margin-bottom: 20px;
    }
`;

export default () => (
  <FooterBlock>
    <PopupWindow title="Scan QR to talk" popupId="line-popup-window" extraClass="a-center">
      <LineQR to={process.env.LINE_ID} text="Line ID"/>
      <p>Add LINE Friends via QR Code</p>
    </PopupWindow>
    <div className="footer-top">
      <Wrapper>
        <Copy>
          <span>{`${process.env.COPY}`}</span>
          <Link to="/sitemap/">Sitemap</Link>
        </Copy>
      </Wrapper>
    </div>
    <Freshchat/>
  </FooterBlock>
);
