import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import IcomoonIcon from './IcomoonIcon';
import { breakpoints, color } from '../../utils/style';

const BreadcrumbsWrap = styled('div')`
  margin: 0 auto 20px;
  max-width: 1215px;
  padding: 15px 2px 0;
  border-top: 2px solid ${color.transparentWhite2};
  div {
    display: inline;
  }
  .sep {
    margin: 0 5px;
    font-size: 14px;
  }
  span {
    color: ${color.transparentWhite8};
  }
  a {
    color: ${color.transparentWhite8};
    line-height: 20px;
    &:active, &:hover {
      color: ${color.white};
      text-shadow: 0 0 9px ${color.peach};
      svg {
        path {fill: ${color.white} !important;}
        filter: drop-shadow(1px 1px 6px ${color.peach});
      }
    }  
  }
  @media (max-width: ${breakpoints.md}) {
    margin: 0 0 20px;
    padding: 15px 0 0;
  }
`;

const Breadcrumbs = ( data ) => {
  const separator = ' \\ ';
  const breadcrumbs =  data.breadcrumbs;
  const page_title = data.page_title;
  return (
     <BreadcrumbsWrap className="breadcrumbs">
      {breadcrumbs && breadcrumbs.length > 0 ?
        breadcrumbs.map((breadcrumb, index) =>
          <div key={index + breadcrumb.slug} >
            <Link to={breadcrumb.slug ? '/' + breadcrumb.slug + '/' : '/'}>
              {!breadcrumb.slug ? 
                <IcomoonIcon icon="home-new" color={color.red} size={20}/>
                : <span>{breadcrumb.title}</span>
              }
            </Link>
            <span className="sep">{separator}</span>
          </div>
        ) : ''}
      <span>{page_title}</span>
    </BreadcrumbsWrap>
  )
};

export default Breadcrumbs