import React from 'react';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const showHideChat = (id, frameID) => {
  const chatBox = document.getElementById(id);
  let chatIframe = document.getElementById(frameID);

  chatBox.classList.toggle('open');

  if (chatBox.classList.contains('open')) {
    document.body.style.overflow = 'hidden';

    if (!chatIframe.src || chatIframe.src === 'about:blank') {
      chatIframe.src = process.env.FRESHCHAT_LOCAL;
    }
  }
  else {
    document.body.style.overflow = 'initial';
  }
};

const MobileContactLink = () => {
  return (
    <a aria-label="Chat" rel="noopener noreferrer" onClick={() => showHideChat('chat-wrapper', 'chat-frame')}>
      <IcomoonIcon className="contact-menu-icon" icon="chat-new" color={color.red} size={30} />
    </a>
  );
};

export default MobileContactLink;
